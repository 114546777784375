import React, { Component } from 'react';
import { Panel, withModal } from '@jutro/components';
import _ from 'lodash';
import { FormattedMessage } from "react-intl";
import { ViewModelForm } from 'gw-portals-viewmodel-react';
import { readViewModelValue } from 'gw-jutro-adapters-react';
import metadata from './SaveEmail.metadata.json5';
import messages from "./SaveEmail.messages";

import styles from './SaveEmail.module.scss';

class SaveEmail extends Component {
    state = {
        emailData: {
            emailAddress: ''
        },
        submissionVM: {},
        showErrors: false
    };

    componentDidMount() {
        const { componentProps } = this.props;
        if (componentProps.baseData.accountHolder.emailAddress1.value === 'dummy@email.dummy') {
            _.set(componentProps, 'baseData.accountHolder.emailAddress1', '');
        }
        this.setState({
            emailData: {
                emailAddress: componentProps.baseData.accountHolder.emailAddress1.value
            },
            submissionVM: componentProps
        });
    }

    validateEmailData = () => {
        const { submissionVM } = this.state;
        if (!_.isEmpty(submissionVM)) {
            const email = this.state.emailData.emailAddress;
            _.set(submissionVM, 'baseData.accountHolder.emailAddress1', email);

            const emailAddress1VMNode = _.get(submissionVM, 'baseData.accountHolder.emailAddress1');
            return (
                !_.isEmpty(emailAddress1VMNode.value)
                && emailAddress1VMNode.aspects.valid
                && emailAddress1VMNode.aspects.subtreeValid
            );
        }


        return false;
    };

    handleDismiss = (evt) => {
        // eslint-disable-next-line react/prop-types
        // const emailError = validateEmailData();
        const { dismissAction } = this.props;
        evt.stopPropagation();
        dismissAction();
    };

    emailUpdate = (value) => {
        // eslint-disable-next-line react/prop-types
        // const emailError = validateEmailData();
        const normalisedValue = value.replace(/[^0-9a-zA-Z-_@.]/ig, '');
        const emailDat = {
            emailAddress: normalisedValue
        };
        this.setState({
            emailData: emailDat
        });
    };

    handleSubmit = () => {
        // eslint-disable-next-line react/prop-types
        // const emailError = validateEmailData();
        const validate = this.validateEmailData();
        if (!validate) {
            this.setState({
                showErrors: true
            });
            return false;
        }
        const { closeAction } = this.props;

        closeAction(this.state);
    }

    getClick = () => {
        return (
            <FormattedMessage
                {...messages.paFirstCaption}
                values={{
                    link:
                        (<a
                            href="https://www.alfainsurance.com/about-alfa/privacy-policy"
                            className={styles.hyperLinkColor}
                            rel="noopener noreferrer"
                            target="_blank"
                        >
                            Privacy Policy
                        </a>
                        )
                }}
            />
        );
    };


    render() {
        // eslint-disable-next-line react/prop-types
        const { emailData, showErrors } = this.state;

        const overrideProps = {
            submit: {
                content: 'Send Email',
                visible: 'true',
            },
            email: {
                showErrors: showErrors,
                placeholder: _.isEmpty(emailData.emailAddress) ? 'Email Address' : ''
            },
            emailError: {
                visible: showErrors
            },
            emailText: {
                content: !_.isEmpty(emailData.emailAddress) ? 'Email Address' : ''
            },
            paFirstCaption: {
                content: this.getClick()
            }

        };
        const resolvers = {
            resolveCallbackMap: {
                onCancel: this.handleDismiss,
                emailUpdate: this.emailUpdate,
                handleSubmit: this.handleSubmit,
                getClick: this.getClick

            },
            resolveClassNameMap: styles,

        };
        const readValue = (id, path) => {
            return readViewModelValue(metadata.componentContent, emailData,
                id, path, overrideProps);
        };

        return (
            <Panel id="standardizedPanel" className={styles.alfaAddStdModal}>
                <ViewModelForm
                    uiProps={metadata.componentContent}
                    model={emailData}
                    overrideProps={overrideProps}
                    resolveValue={readValue}
                    classNameMap={resolvers.resolveClassNameMap}
                    callbackMap={resolvers.resolveCallbackMap}
                />
            </Panel>
        );
    }
}

export default withModal(SaveEmail);
