import { defineMessages } from 'react-intl';

export default defineMessages({
    paFirstCaption: {
        id: 'quoteandbind.pa.directives.more-Info.paFirstCaption',
        defaultMessage: 'By providing my email address and or phone number, I understand and agree this is where I will receive a copy of my quote and other related communications such as emails, phone calls, or texts, as applicable. If you no longer choose to receive an email, you may click "Unsubscribe" at the bottom of the email, or if you no longer wish to receive a text, you may type "Stop" in response. For texts, message frequency may vary, and message and data rates may apply.  Please see the {link} for additional information.',
    },
    phoneArea:{
        id: 'quoteandbind.pa.directives.more-Info.phoneArea',
        defaultMessage: '999'
    },
    phoneHypen: {
        id: 'quoteandbind.pa.directives.more-Info.phoneHypen',
        defaultMessage: '-'
    },
    emailError: {
        id: 'quoteandbind.pa.directives.more-Info.emailError',
        defaultMessage: 'Please enter a valid email address'
    },
    phoneError: {
        id: 'quoteandbind.pa.directives.more-Info.phoneError',
        defaultMessage: 'Please enter a valid 10 digit phone number'
    },
    paMoreInfoPageTitle: {
        id: 'quoteandbind.views.more-Info.quote',
        defaultMessage: 'Your Quote is Ready!'
    },
    paMoreInfoPageSubTitle: {
        id: 'quoteandbind.views.more-Info.contactInfo',
        defaultMessage: 'Enter your contact info to get your quote'
    },
    emailErrorNew: {
        id: 'quoteandbind.views.more-Info.emailError',
        defaultMessage: 'Please enter a valid email address'
    },
    email: {
        id: 'quoteandbind.views.more-Info.emailAddress',
        defaultMessage: 'Email Address'
    },
    phoneError: {
        id: 'quoteandbind.views.more-Info.phoneError',
        defaultMessage: 'please Enter a valid Phone Number'
    },
    mobileNumber: {
        id: 'quoteandbind.views.more-Info.phoneNumber',
        defaultMessage: 'Phone Number'
    },

});
