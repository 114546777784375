import { defineMessages } from 'react-intl';

export default defineMessages({
    paFirstCaption: {
        id: 'portal.index.emailFooter',
        defaultMessage: 'By providing my email address and or phone number, I understand and agree this is where I will receive a copy of my quote and other related communications such as emails, phone calls, or texts, as applicable. If you no longer choose to receive an email, you may click "Unsubscribe" at the bottom of the email, or if you no longer wish to receive a text, you may type "Stop" in response. For texts, message frequency may vary, and message and data rates may apply.  Please see the {link} for additional information.',
    },
    emailHeader: {
        id: 'portal.index.emailHeader',
        defaultMessage: 'Your Quote has been Saved!'
    },
    emailLabel: {
        id: 'portal.index.emailLabel',
        defaultMessage: 'Enter your email address to receive a link to pick up where you left off.'
    },
    isResponsiblePlayer: {
        id: 'quoteandbind.common.directives.QuoteDiscountApp.isResponsiblePlayer',
        defaultMessage: 'Responsible Payer',
    },
    isYouthHonorStudent: {
        id: 'quoteandbind.common.directives.QuoteDiscountApp.isYouthHonorStudent',
        defaultMessage: 'Youth Honor Student*',
    },
    hasDriverTrainingDiscount: {
        id: 'quoteandbind.common.directives.QuoteDiscountApp.hasDriverTrainingDiscount',
        defaultMessage: 'Driver Training*',
    },
    isYouthDefensiveDriver: {
        id: 'quoteandbind.common.directives.QuoteDiscountApp.isYouthDefensiveDriver',
        defaultMessage: 'Youth Defensive Driver*',
    },
    isDefensiveDriver: {
        id: 'quoteandbind.common.directives.QuoteDiscountApp.isDefensiveDriver',
        defaultMessage: 'Defensive Driver*',
    },
    manualDiscount: {
        id: 'quoteandbind.common.directives.QuoteDiscountApp.manualDiscount',
        defaultMessage: '* These discounts will require a few more steps in order to keep them once you purchase. Click each discount for more details.',
    },
    isyouthSafety: {
        id: 'quoteandbind.common.directives.QuoteDiscountApp.isyouthSafety',
        defaultMessage: 'Youth Safety*',
    },
    isyouthSafetyDescription: {
        id: 'quoteandbind.common.directives.QuoteDiscountApp.isyouthSafetyDescription',
        defaultMessage: 'For an unmarried driver, aged 16-20, who has completed a qualified course and has had no chargeable accidents or violations in the past three years.',
    },
    hasOccupationDiscount: {
        id: 'quoteandbind.common.directives.QuoteDiscountApp.hasOccupationDiscount',
        defaultMessage: 'Occupation',
    },
    hasAffinityDiscount: {
        id: 'quoteandbind.common.directives.QuoteDiscountApp.hasAffinityDiscount',
        defaultMessage: 'Affinity',
    },
    isResponsiblePlayerDescription: {
        id: 'quoteandbind.common.directives.QuoteDiscountApp.isResponsiblePlayerDescription',
        defaultMessage: 'Available for new Alfa® auto policies and will remain on the policy if there are no late payments or a lapse in coverage for that policy.',
    },
    isYouthHonorStudentDescription: {
        id: 'quoteandbind.common.directives.QuoteDiscountApp.isYouthHonorStudentDescription',
        defaultMessage: 'Driver must be a full-time student (12 hours or more) with a 3.0 GPA or higher. To keep this discount, scholastic records must be provided. Age requirement is unmarried between 16-24.',
    },
    hasDriverTrainingDiscountDescription: {
        id: 'quoteandbind.common.directives.QuoteDiscountApp.hasDriverTrainingDiscountDescription',
        defaultMessage: 'Available for drivers aged 16-20 with classroom instruction and behind-the-wheel training by a certified instructor.',
    },
    isYouthDefensiveDriverDescription: {
        id: 'quoteandbind.common.directives.QuoteDiscountApp.isYouthDefensiveDriverDescription',
        defaultMessage: 'For an unmarried driver, aged 16-20, who has completed a qualified course and has had no chargeable accidents or violations in the past three years.',
    },
    isDefensiveDriverDescription: {
        id: 'quoteandbind.common.directives.QuoteDiscountApp.isDefensiveDriverDescription',
        defaultMessage: 'For a driver, aged 16-20 or age 55 and over, who has completed a qualified course and has had no chargeable accidents or violations in the past three years.',
    },
    isDefensiveDriverDescriptionGa: {
        id: 'quoteandbind.common.directives.QuoteDiscountApp.isDefensiveDriverDescriptionGa',
        defaultMessage: 'For a driver of any age who has completed a qualified course and has had no chargeable accidents or violations in the past three years.',
    },
    hasOccupationDiscountDescription: {
        id: 'quoteandbind.common.directives.QuoteDiscountApp.hasOccupationDiscountDescription',
        defaultMessage: 'Available if the primary named insured or spouse is an educator, farmer, minister, first responder or in the military.',
    },
    hasOccupationDiscountDescriptionGa: {
        id: 'quoteandbind.common.directives.QuoteDiscountApp.hasOccupationDiscountDescriptionGa',
        defaultMessage: 'Available if the primary named insured or spouse is in the military.',
    },
    hasAffinityDiscountDescription: {
        id: 'quoteandbind.common.directives.QuoteDiscountApp.hasAffinityDiscountDescription',
        defaultMessage: 'Available if any member of the household is a state or federal employee.',
    },
    hasMultiCarDiscount: {
        id: 'quoteandbind.common.directives.QuoteDiscountApp.hasMultiCarDiscount',
        defaultMessage: 'Multi-Car',
    },
    hasMultiCarDiscountDescription: {
        id: 'quoteandbind.common.directives.QuoteDiscountApp.hasMultiCarDiscountDescription',
        defaultMessage: 'Available when two or more private passenger vehicles are insured with Alfa.',
    },
    hasExcessVehicleDiscount: {
        id: 'quoteandbind.common.directives.QuoteDiscountApp.hasExcessVehicleDiscount',
        defaultMessage: 'Excess-Vehicle',
    },
    hasExcessVehicleDiscountDescription: {
        id: 'quoteandbind.common.directives.QuoteDiscountApp.hasExcessVehicleDiscountDescription',
        defaultMessage: 'Available if the number of vehicles on the policy is greater than the number of drivers on the policy (not including excluded drivers).',
    },
    hasHomeOwnerDiscount: {
        id: 'quoteandbind.common.directives.QuoteDiscountApp.hasHomeOwnerDiscount',
        defaultMessage: 'Homeownership',
    },
    hasHomeOwnerDiscountDescription: {
        id: 'quoteandbind.common.directives.QuoteDiscountApp.hasHomeOwnerDiscountDescription',
        defaultMessage: 'If you own a home, with or without a mortgage, you qualify for this discount.',
    },    
    hasRelationshipDiscount: {
        id: 'quoteandbind.common.directives.QuoteDiscountApp.hasRelationshipDiscount',
        defaultMessage: 'Relationship*',
    },
    hasRelationshipDiscountDescription: {
        id: 'quoteandbind.common.directives.QuoteDiscountApp.hasRelationshipDiscountDescription',
        defaultMessage: 'Available when you choose to insure multiple policies with Alfa®.'
    },
});
