import { defineMessages } from 'react-intl';

export default defineMessages({
    welcomeText: {
        id: 'quoteandbind.pa.directives.Confirmation.welcomeText',
        defaultMessage: 'Welcome to the Alfa Family!',
    },
    thankMessage: {
        id: 'quoteandbind.pa.directives.Confirmation.thankMessage',
        defaultMessage: "Thank you for trusting us with your auto insurance needs. We're here for you.",
    },
    perksText: {
        id: 'quoteandbind.pa.directives.Confirmation.perksText',
        defaultMessage: 'Perks of Being an Alfa Customer',
    },
    discountText: {
        id: 'quoteandbind.pa.directives.Confirmation.discountText',
        defaultMessage: 'Get the coverage you want with the discounts you deserve',
    },
    benefitsText: {
        id: 'quoteandbind.pa.directives.Confirmation.benefitsText',
        defaultMessage: 'Discover local and national member benefits',
    },
    homeText: {
        id: 'quoteandbind.pa.directives.Confirmation.homeText',
        defaultMessage: 'Protect more with home and life insurance bundles',
    },
    policyNumber: {
        id: 'quoteandbind.pa.directives.Confirmation.policyNumber',
        defaultMessage: 'Policy Number',
    },
    policyTerm: {
        id: 'quoteandbind.pa.directives.Confirmation.policyTerm',
        defaultMessage: 'Policy Term',
    },
    membershipNumber: {
        id: 'quoteandbind.pa.directives.Confirmation.memberShipNumber',
        defaultMessage: 'Membership Number',
    },
    youAreAllSet: {
        id: 'quoteandbind.pa.directives.Confirmation.youAreAllSet',
        defaultMessage: 'You’re all set!',
    },
    whatsNext: {
        id: 'quoteandbind.pa.directives.Confirmation.whatsNext',
        defaultMessage: 'What’s Next?',
    },
    emailText: {
        id: 'quoteandbind.pa.directives.Confirmation.emailText',
        defaultMessage: 'We sent a confirmation link to you at',
    },
    confirmationLink: {
        id: 'quoteandbind.pa.directives.Confirmation.confirmationLink',
        defaultMessage: 'Complete Your MyAlfa® Account'
    },
    alfaAccountActiveMsgFirst: {
        id: 'quoteandbind.pa.directives.Confirmation.alfaAccountActiveMsgFirst',
        defaultMessage: 'We’ve sent an email to'
    },
    alfaAccountActiveMsgSecond: {
        id: 'quoteandbind.pa.directives.Confirmation.alfaAccountActiveMsgSecond',
        defaultMessage: 'with a link to create a password for your account. Once you set your password, you’ll be able to access your policy online.'
    },
    callToVerifyEmailMsg: {
        id: 'quoteandbind.pa.directives.Confirmation.callToVerifyEmailMsg',
        defaultMessage: 'The link will remain active for 7 days. If your link expires or you do not receive an email, please call 1-800-964-2532 for assistance.'
    },
    meetYourAgent: {
        id: 'quoteandbind.pa.directives.Confirmation.meetYourAgent',
        defaultMessage: 'Meet your agent'
    },
    viewAgentBioPage: {
        id: 'quoteandbind.pa.directives.Confirmation.viewAgentBioPage',
        defaultMessage: 'View Agent Bio Page'
    },
    wooHoo: {
        id: 'quoteandbind.pa.directives.Confirmation.wooHoo',
        defaultMessage: 'Woo-Hoo!' 
    },
    relationshipDiscountMessageHomeowners: {
        id: 'quoteandbind.pa.directives.Confirmation.relationshipDiscountMessageHomeowners',
        defaultMessage: 'You selected to bundle and save, so your agent will be in touch to get your home (or renters) quote started!' 
    },
    relationshipDiscountMessageRenters: {
        id: 'quoteandbind.pa.directives.Confirmation.relationshipDiscountMessageRenters',
        defaultMessage: 'You selected to bundle and save, so your agent will be in touch to get your renters quote started!' 
    },
    bundleAndSaveAltTextHomeowners: {
        id: 'quoteandbind.pa.directives.Confirmation.bundleAndSaveAltTextHomeowners',
        defaultMessage: 'Car plus Home equals Savings' 
    },
    bundleAndSaveAltTextRenters: {
        id: 'quoteandbind.pa.directives.Confirmation.bundleAndSaveAltTextRenters',
        defaultMessage: 'Car plus Renter equals Savings' 
    }

});
