import React, {
    useCallback, useEffect, useRef, useContext,
} from 'react';
import _ from 'lodash';


import { ViewModelForm } from 'gw-portals-viewmodel-react';
import { readViewModelValue } from 'gw-jutro-adapters-react';


import { LoadSaveService } from 'gw-capability-quoteandbind';
import appConfig from 'app-config';
import { TranslatorContext } from '@jutro/locale';


// eslint-disable-next-line import/no-unresolved

import styles from './Confirmation.module.scss';
import metadata from './Confirmation.metadata.json5';
import messages from './Confirmation.messages';


function Confirmation(props) {
    const { submission: submissionVM } = props.location.state;
    const selectedAgentInfoRef = useRef(
        submissionVM.baseData.agentInfo && submissionVM.baseData.agentInfo.agentInfo &&
        submissionVM.baseData.agentInfo.agentInfo[0]
    ); 
    const translator = useContext(TranslatorContext);
    const bundleInsurance = !_.isUndefined(submissionVM.lobData.personalAuto.bundleInsurance) ? submissionVM.lobData.personalAuto.bundleInsurance : false;
    const ownOrRent = !_.isUndefined(submissionVM.lobData.personalAuto.ownOrRent) ? submissionVM.lobData.personalAuto.ownOrRent : 'undef';


    const getAddress = useCallback(() => {
        if (!_.isUndefined(selectedAgentInfoRef.current)) {
            const postalCode = selectedAgentInfoRef.current.address.postalCode.substring(0, 5);
            return (
              <div className={styles.mt5}>
                <span className={styles.agentText}>
                  <span>
                    <img
                      className={styles.agentIcon}
                      alt="agentlocationIcon"
                      src="alfa-images/location.png"
                    />
                  </span>
                  &nbsp;
                  {selectedAgentInfoRef.current.address.addressLine1}
                  &nbsp;&nbsp;
                  <br />
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  {selectedAgentInfoRef.current.address.city},
                  {selectedAgentInfoRef.current.address.state}
                  &nbsp;
                  {postalCode}
                </span>
              </div>
            );
        }
        return '';
    }, []);

    const printQuote = useCallback(() => {
        const { quoteID } = submissionVM;
        const printDocument = window.open();
        
        const responsePromise = LoadSaveService.generatePrintDocumentToken();
        responsePromise.then((response) => {
            const sessionToken = response;
            const mockURI = appConfig.servers.pc.url;
            const effURI = mockURI.substring(mockURI.indexOf('/pc'));
            const xCenterDownloadURI = effURI.replace('unauthenticated/', '');
            const documentUrl = xCenterDownloadURI.concat('document/document/policyDocument/?token=', sessionToken, '&submissionNumber=', quoteID, '&isConfirmationPrintId=true');
            
            printDocument.location = documentUrl;
            printDocument.target = '_blank';

            if (_.isNull(printDocument)) {
                printDocument.focus();
               printDocument.print();
            }
        });
    }, []);

    const getPhoneNumber = useCallback((isMobile) => {
        if (!_.isUndefined(selectedAgentInfoRef.current)) {
            return (
                <div>
                    <span ><img className={styles.agentIcon} alt="agentPhoneIcon" src="alfa-images/phone.png" />
                        {isMobile ?
                            (
                                <a id="qb_confirmation_call" className={styles.mobileAgentPhone} href={`tel:${selectedAgentInfoRef.current.phoneNumber}`}>
                                    {selectedAgentInfoRef.current.phoneNumber}
                                </a>
                            )
                            :
                            (
                                <a id="qb_confirmation_call" className={styles.phoneUnderline}>
                                    {selectedAgentInfoRef.current.phoneNumber}
                                </a>
                            )
                        }
                    </span>
                </div>
            );
        }
        return '';
    }, []);

    const sendEmail = (e, mail) => {
        e.preventDefault();
        window.location = `mailto:${mail}`;
    }

    const getEmail = useCallback(() => {
        if (!_.isUndefined(selectedAgentInfoRef.current)) {
            return (
              <div class={styles.emailLineStyle}>
                <span
                  className={styles.agentEmail}
                  onClick={(e) =>
                    sendEmail(e, selectedAgentInfoRef.current.email)
                  }
                >
                  <img
                    className={styles.agentIcon}
                    alt="agentEmailIcon"
                    src="alfa-images/agentemail.png"
                  />
                  <span className={styles.ml6}>{selectedAgentInfoRef.current.email}</span>
                </span>
              </div>
            );
        }
        return '';
    }, []);

    const getAgentName = useCallback(() => {
        if (!_.isUndefined(selectedAgentInfoRef.current)) {
            return (
                <span>
                    {selectedAgentInfoRef.current.firstName}
                &nbsp;
                    {selectedAgentInfoRef.current.lastName}
                </span>
            );
        }
        return '';
    }, []);

    const getPolicyTerm = useCallback(() => {
        const policy = submissionVM.baseData;
        const startmonth = policy.periodStartDate.month + 1;
        const startdateString = ''.concat(startmonth, '/', policy.periodStartDate.day, '/', policy.periodStartDate.year);
        const endMonth = policy.periodEndDate.month + 1;
        const enddateString = ''.concat(endMonth, '/', policy.periodEndDate.day, '/', policy.periodEndDate.year);
        const policyTerm = `${startdateString} - ${enddateString}`;
        const termLabel = 'quoteandbind.pa.directives.Confirmation.policyTerm';
        const termMessage = translator(_.find(messages, { id: termLabel }));
        return (
            <div>
                <span>
                    {termMessage}
                    <br />
                    {policyTerm}
                </span>
            </div>
        );
    }, []);

    const getPolicyNumber = useCallback(() => {
        const { policyNumber } = submissionVM.bindData;
        const policyLabel = 'quoteandbind.pa.directives.Confirmation.policyNumber';
        const policyMessage = translator(_.find(messages, { id: policyLabel }));
        return (
            <span className={styles.policy}>
                {policyMessage}
                <br />
                {policyNumber}
            </span>
        );
    }, []);

    const getMembership = useCallback(() => {
        const clientID = submissionVM.baseData.clientID_alfa;
        let membership = '';
        if (!_.isUndefined(clientID)) {
            membership = clientID;
        }
        const membershipLabel = 'quoteandbind.pa.directives.Confirmation.memberShipNumber';
        const membershipMessage = translator(_.find(messages, { id: membershipLabel }));
        return (
            <div>
                <span>
                    {membershipMessage}
                    <br />
                    {membership}
                    &nbsp;
                </span>
            </div>
        );
    }, []);

    const getWelcomeText = useCallback(() => {
        const welcomeLabel = 'quoteandbind.pa.directives.Confirmation.welcomeText';
        const welcomeMessage = translator(_.find(messages, { id: welcomeLabel }));

        return (
            <span>
                {welcomeMessage}
            </span>
        );
    }, []);

    const getYouAreAllSet = useCallback(() => {
        const youAreAllSetLabel = 'quoteandbind.pa.directives.Confirmation.youAreAllSet';
        const youAreAllSetMessage = translator(_.find(messages, { id: youAreAllSetLabel }));

        return (
            <span>
                {youAreAllSetMessage}
            </span>
        );
    }, []);

    const getPolicydetails = useCallback(() => {
        return (
            <span>
                <table className={styles.infoTable}>
                    <tr>
                        <td>{getMembership()}</td>
                        <td className={styles.tdTextAlign}>{getPolicyTerm()}</td>
                    </tr>
                </table>
            </span>
        );
    }, []);

    const getPolicyGAMSDetails = useCallback(() => {
        return (
            <span>
                <table className={styles.infoTable}>
                    <tr>
                        <td>{getPolicyNumber()}</td>
                        <td className={styles.tdTextAlign}>{getPolicyTerm()}</td>
                    </tr>
                </table>
            </span>
        );
    }, []);

    const getPolicyDetailsMobile = useCallback(() => {
        const isGAMS = ['GA', 'MS'].includes(submissionVM.baseData.accountHolder.primaryAddress.state);
        return (
            <span>
                <table className={styles.infoTableMobile}>
                    {
                        !isGAMS ?
                            (<tr>
                                <td className={styles.infoTableMobileTd}>{getMembership()}</td>
                            </tr>) : null
                    }
                    <tr>
                        <td className={styles.infoTableMobileTd}>{getPolicyNumber()}</td>
                    </tr>
                    <tr>
                        <td className={styles.infoTableMobileTd}>{getPolicyTerm()}</td>
                    </tr>
                </table>
            </span>
        );
    }, []);

    const getAccountCreationText = useCallback(() => {
        const { contactEmail } = submissionVM.bindData;

        let [confirmationLinkMessage, alfaAccountActiveMessageFirst, alfaAccountActiveMessageSecond, callToVerifyEmailMessage] = whatsNext();

        return (
            <span>
                <table className={styles.infoTable}>
                    <tr>
                        <td className={styles.accountCreationTd}><img className={styles.emailImage} alt="emailIcon" src="alfa-images/emailIcon.svg" /></td>
                        <td className={styles.activeMsg}>
                            <span className={styles.emailText}>{confirmationLinkMessage}</span>
                            <span className={styles.whatNext}>{alfaAccountActiveMessageFirst} {contactEmail} {alfaAccountActiveMessageSecond}</span>
                            <span className={styles.callMessage}>{callToVerifyEmailMessage}</span>
                        </td>
                    </tr>
                </table>
            </span>
        );
    }, []);

    const getAccountCreationMobileText = useCallback(() => {
        const { contactEmail } = submissionVM.bindData;

        let [confirmationLinkMessage, alfaAccountActiveMessageFirst, alfaAccountActiveMessageSecond, callToVerifyEmailMessage] = whatsNext();

        return (
            <span>
                <table className={styles.infoTableEmail}>
                    <tr>
                        <td className={styles.accountCreationTd}><img className={styles.mobileEmailImage} alt="emailIconMobile" src="alfa-images/email.png" /></td>
                    </tr>
                    <tr>
                        <td>
                            <span className={styles.emailText}>{confirmationLinkMessage}</span>
                        </td>
                    </tr>
                    <tr>
                        <td><span>{alfaAccountActiveMessageFirst} {contactEmail} {alfaAccountActiveMessageSecond}</span></td>
                    </tr>
                    <tr>
                        <td><span>{callToVerifyEmailMessage}</span></td>
                    </tr>
                </table>
            </span>
        );
    }, []);

    const whatsNext = () => {
            const confirmationLinkLabel = 'quoteandbind.pa.directives.Confirmation.confirmationLink';
        const confirmationLinkMessage = translator(_.find(messages, { id: confirmationLinkLabel }));

        const alfaAccountActiveMsgLabeFirst = 'quoteandbind.pa.directives.Confirmation.alfaAccountActiveMsgFirst';
        const alfaAccountActiveMessageFirst = translator(_.find(messages,
            { id: alfaAccountActiveMsgLabeFirst }));

        const alfaAccountActiveMsgLabelSecond = 'quoteandbind.pa.directives.Confirmation.alfaAccountActiveMsgSecond';
        const alfaAccountActiveMessageSecond = translator(_.find(messages,
            { id: alfaAccountActiveMsgLabelSecond }));

        const callToVerifyEmailMsgLabel = 'quoteandbind.pa.directives.Confirmation.callToVerifyEmailMsg';
        const callToVerifyEmailMessage = translator(_.find(messages,
            { id: callToVerifyEmailMsgLabel }));

        return [confirmationLinkMessage,alfaAccountActiveMessageFirst,alfaAccountActiveMessageSecond,callToVerifyEmailMessage];    
    }

    const getAgentDetails = useCallback(() => {
        const meetYourAgentLable = 'quoteandbind.pa.directives.Confirmation.meetYourAgent';
        const viewAgentBioPageLable  = 'quoteandbind.pa.directives.Confirmation.viewAgentBioPage';
        const meetYourAgentMessage = translator(_.find(messages, { id: meetYourAgentLable }));
        const viewAgentBioPageMessage = translator(_.find(messages, { id: viewAgentBioPageLable }));
        if (!_.isUndefined(selectedAgentInfoRef.current)) {
            return (
              <span>
                <span
                  className={styles.agentNameText + " " + styles.meetMargin}
                >
                  {meetYourAgentMessage}
                </span>
                <table className={styles.infoTable}>
                  <tr>
                    <td className={styles.agentAccountCreationTd}>
                      <img
                        className={styles.agentImage}
                        alt="agentImage"
                        src={selectedAgentInfoRef.current.imageURL}
                      />
                    </td>

                    <td className={styles.alignText}>
                      <span className={styles.agentName}>{getAgentName()}</span>

                      <span>{getAddress()}</span>
                    </td>

                    <td>
                      <div className={styles.numWidth}></div>
                      <span className={styles.agentPhoneNumber}>
                        {getPhoneNumber(false)}
                      </span>
                      <br />
                      <span className={styles.agentEmailText}>
                        {getEmail()}
                      </span>
                      <br />
                      <span
                        className={styles.agentBioPage}
                        onClick={() =>
                          (window.location =
                            selectedAgentInfoRef.current.agentBioUrl)
                        }
                      >
                        <img
                          className={styles.agentIcon}
                          alt="agentBioImage"
                          src="alfa-images/webpage.png"
                        />
                        <span className={styles.ml6}>
                          {viewAgentBioPageMessage}
                        </span>
                      </span>
                    </td>
                  </tr>
                </table>
              </span>
            );
        }
        return '';
    }, []);

    const getAgentMobileDetails = useCallback(() => {
        const meetYourAgentLable = 'quoteandbind.pa.directives.Confirmation.meetYourAgent';
        const viewAgentBioPageLable  = 'quoteandbind.pa.directives.Confirmation.viewAgentBioPage';
        const meetYourAgentMessage = translator(_.find(messages, { id: meetYourAgentLable }));
        const viewAgentBioPageMessage = translator(_.find(messages, { id: viewAgentBioPageLable }));
        if (!_.isUndefined(selectedAgentInfoRef.current)) {
            return (
              <span>
                <span className={styles.agentNameText}>
                  {meetYourAgentMessage}
                </span>
                <table className={styles.infoTable}>
                  <tr>
                    <td className={styles.agentImageMobileTd}>
                      <img
                        className={styles.agentImageMobile}
                        src={selectedAgentInfoRef.current.imageURL}
                        alt="agentImageMobile"
                      />
                    </td>
                    <td >
                      <span className={styles.agentName}>{getAgentName()}</span>
                      <span className={styles.agentAddressMobile}>
                        {getAddress()}
                      </span>
                    </td>
                  </tr>
                </table>
                <span >
                  {getPhoneNumber(true)}
                </span>
                <span className={styles.agentEmailText}>{getEmail()}</span>
                <span
                  className={styles.mobileAgentBioPage}
                  onClick={() =>
                    (window.location = selectedAgentInfoRef.current.agentBioUrl)
                  }
                >
                  <img
                    className={styles.agentIcon}
                    alt="agentBioMobile"
                    src="alfa-images/webpage.png"
                  />
                  <span className={styles.ml6}>{viewAgentBioPageMessage}</span>
                </span>
              </span>
            );
        }
        return '';
    }, []);

    const getWhatsNext = useCallback(() => {
        const whatsNextLabel = 'quoteandbind.pa.directives.Confirmation.whatsNext';
        const whatsNextMessage = translator(_.find(messages, { id: whatsNextLabel }));

        return (
            <span>
                {whatsNextMessage}
            </span>
        );
    }, []);

    const getWooHoo = useCallback(() => {
        const wooHooLabel = 'quoteandbind.pa.directives.Confirmation.wooHoo';
        const wooHooMessage = translator(_.find(messages, { id: wooHooLabel }));

        return (
            <span>
                {wooHooMessage}
            </span>
        );
    }, []);

    const getRelationshipMessage = useCallback(() => {
        const hasHomeownerDiscount = bundleInsurance && (ownOrRent === 'Own');

        //If the user has the homeowner discount, get the Homeowners message, else default to the Renters message
        //If a user does not have a relationship discount (bundleInsurance is false) then it will not matter what message is chosen
        // because the gridItem4 will not be displayed. See line 497
        const relationshipLabel = hasHomeownerDiscount ? 
        'quoteandbind.pa.directives.Confirmation.relationshipDiscountMessageHomeowners' 
        : 'quoteandbind.pa.directives.Confirmation.relationshipDiscountMessageRenters';

        const relationshipMessage = translator(_.find(messages, { id: relationshipLabel }));

        return (
            <span>
                {relationshipMessage}
            </span>
        );
    }, []);

    const getBundleAndSaveImage = useCallback(() => {
        const hasHomeownerDiscount = bundleInsurance && (ownOrRent === 'Own');

        //If the user has the homeowner discount, get the Homeowners message, else default to the Renters message
        //If a user does not have a relationship discount (bundleInsurance is false) then it will not matter what message is chosen
        // because the gridItem4 will not be displayed. See line 497
        const imgSrc = hasHomeownerDiscount ? 
        'BundleAndSaveHomeowners.png' 
        : 'BundleAndSaveRenters.png';

        const altLabel = hasHomeownerDiscount ?
        'quoteandbind.pa.directives.Confirmation.bundleAndSaveAltTextHomeowners' :
        'quoteandbind.pa.directives.Confirmation.bundleAndSaveAltTextRenters'

        const altTextMessage = translator(_.find(messages, { id: altLabel }));

        return (
             <span>
                 <img className={styles.bundleAndSave} src={imgSrc} alt={altLabel} />
             </span>
         );
    }, []);


    //TODO: Can this be made generic? Previous functions repeat the same pattern
    // const getGenericMessage = useCallback(() => {
    //     const wooHooLabel = 'quoteandbind.pa.directives.Confirmation.wooHoo';
    //     const wooHooMessage = translator(_.find(messages, { id: wooHooLabel }));

    //     return (
    //         <span>
    //             {wooHooMessage}
    //         </span>
    //     );
    // }, []);

    const overrideProps = {

        agentAddress: {
            content: getAddress()
        },
        agentEmail: {
            content: getEmail()
        },
        agentName: {
            value: getAgentName()
        },
        welcomeText: {
            content: getWelcomeText()
        },
        youAreAllSet: {
            content: getYouAreAllSet()
        },
        policydetails: {
            content: getPolicydetails()
        },
        policyDetailsMobile: {
            content: getPolicyDetailsMobile()
        },
        policyGAMSContainer: {
            visible: ['GA', 'MS'].includes(submissionVM.baseData.accountHolder.primaryAddress.state)
        },
        policyContainer: {
            visible: !['GA', 'MS'].includes(submissionVM.baseData.accountHolder.primaryAddress.state)
        },
        policyGAMSdetails: {
            content: getPolicyGAMSDetails()
        },
        accountCreationText: {
            content: getAccountCreationText()
        },
        accountCreationMobileText: {
            content: getAccountCreationMobileText()
        },
        gridItem3: {
            visible: !_.isUndefined(selectedAgentInfoRef.current)
        },
        mobilegridItem3: {
            visible: !_.isUndefined(selectedAgentInfoRef.current)
        },
        gridItem4: {
            visible: bundleInsurance
        },
        mobilegridItem4: {
            visible: bundleInsurance
        },
        agentDetails: {
            content: getAgentDetails()
        },
        agentDetailsMobile: {
            content: getAgentMobileDetails()
        },
        whatsNext: {
            content: getWhatsNext()
        },
        wooHoo: {
            content: getWooHoo()
        },
        relationshipDiscountMessage: {
            content: getRelationshipMessage()
        },
        bundleAndSaveImage: {
            content: getBundleAndSaveImage()
        },
        agentImage: {
            src: !_.isUndefined(selectedAgentInfoRef.current) ? selectedAgentInfoRef.current.imageURL : ''
        },
        policyNumber: {
            content: getPolicyNumber()
        },
        policyTerm: {
            content: getPolicyTerm()
        },
        membershipTerm: {
            content: getMembership()
        }
    };

    const readValue = useCallback(
        (id, path) => {
            return readViewModelValue(metadata.pageContent, submissionVM,
                id, path, overrideProps);
        },
        [overrideProps, submissionVM]
    );

    const resolvers = {
        resolveClassNameMap: styles,
        resolveCallbackMap: {
            printQuote: printQuote
        },
        resolveComponentMap: {
        }
    };

    return (
        <ViewModelForm
            uiProps={metadata.pageContent}
            model={submissionVM}
            overrideProps={overrideProps}
            resolveValue={readValue}
            classNameMap={resolvers.resolveClassNameMap}
            callbackMap={resolvers.resolveCallbackMap}
            componentMap={resolvers.resolveComponentMap}
        />
    );
}

Confirmation.propTypes = {
};

Confirmation.defaultProps = {
};
export default Confirmation;
